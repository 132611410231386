import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Collapse,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  Check as CorrectIcon,
  Room as MarkerIcon,
  Clear as WrongIcon,
} from '@material-ui/icons/';
import clsx from 'clsx';
import AudioPlayer from 'material-ui-audio-player';
import { useState, VFC } from 'react';
import { FlyToInterpolator } from 'react-map-gl';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';

import { useGeolocation } from '../hooks/useGeolocation';
import { useTaskStatus } from '../hooks/useTaskStatus';
import { AdventureTask } from '../queries/useAdventure';
import { useTaskMap } from '../stores/useTaskMap';
import { distanceToTask } from '../util/inRange';
import { Markdown } from './Markdown';
import { TaskModal } from './TaskModal';
import { TaskModalToggle } from './TaskModalToggle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
    rootDisabled: {
      backgroundColor: theme.palette.action.disabledBackground,
    },
    rootDisabledCorrect: {
      backgroundColor: `${theme.palette.success.light}66`,
    },
    rootDisabledWrong: {
      backgroundColor: `${theme.palette.error.light}66`,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    shortDescription: {
      '& p:first-child': {
        marginTop: 0,
      },
      '& p:last-child': {
        marginBottom: 0,
      },
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
    },
    avatarCorrect: {
      backgroundColor: theme.palette.success.main,
    },
    avatarWrong: {
      backgroundColor: theme.palette.error.main,
    },
    avatarDisabled: {
      backgroundColor: theme.palette.action.disabled,
    },
    markerIcon: {},
    markerIconDisabled: {
      fill: theme.palette.action.disabled,
    },
    open: { marginLeft: 'auto' },
    openIcon: {},
  })
);

const useAudioStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  progressTime: {
    color: theme.palette.text.secondary,
  },
  playIcon: {
    transition: '300ms',
  },
  pauseIcon: {
    transition: '300ms',
  },
  volumeIcon: {
    transition: '300ms',
  },
}));

type TaskListItemProps = {
  task: AdventureTask;
};

export const TaskListItem: VFC<TaskListItemProps> = ({
  task,
}: TaskListItemProps): JSX.Element => {
  const classes = useStyles();

  const { mapViewportPropsFn } = useTaskMap();

  const { position } = useGeolocation();

  const [modalOpen, setModalOpen] = useState(false);

  const { inactive, inactiveReasons } = useTaskStatus(task);

  const { path } = useRouteMatch();

  const getInactiveReasonsElement = (): JSX.Element => (
    <Typography variant="caption">{inactiveReasons.join(', ')}</Typography>
  );

  const getDistanceElement = (): JSX.Element => {
    const d = distanceToTask(position, task);

    if (d >= 0) {
      return (
        <Typography variant="body2" color="textSecondary">
          {`${d.toFixed(1)}m`}
        </Typography>
      );
    }

    return <></>;
  };

  const getAvatar = (): JSX.Element => {
    if (!task.solution) {
      return (
        <Avatar
          className={clsx(classes.avatar, [
            inactive && classes.avatarDisabled,
          ])}>
          <Typography variant="button">{task.displayId}</Typography>
        </Avatar>
      );
    }
    if (task.solutionCorrect) {
      return (
        <Avatar
          className={clsx(classes.avatarCorrect, [
            inactive && classes.avatarDisabled,
          ])}>
          <CorrectIcon />
        </Avatar>
      );
    }
    return (
      <Avatar
        className={clsx(classes.avatarWrong, [
          inactive && classes.avatarDisabled,
        ])}>
        <WrongIcon />
      </Avatar>
    );
  };

  const handleMarkerClick = () => {
    mapViewportPropsFn({
      latitude: task.location.lat,
      longitude: task.location.lng,
      zoom: 16,
      transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
      transitionDuration: 500,
    });
  };

  return (
    <Card
      className={clsx(classes.root, [
        inactive && !task.solution && classes.rootDisabled,
        inactive &&
          task.solution &&
          !task.solutionCorrect &&
          classes.rootDisabledWrong,
        inactive &&
          task.solution &&
          task.solutionCorrect &&
          classes.rootDisabledCorrect,
      ])}
      style={{ order: Math.round(distanceToTask(position, task)) }}>
      <TaskModal
        openState={[modalOpen, setModalOpen]}
        task={task}
        closePath={`${path}/list`}
      />
      <Switch>
        <Route exact path={`${path}/list/${task.id}`}>
          <TaskModalToggle open openState={[modalOpen, setModalOpen]} />
        </Route>
        <Route exact path={`${path}/list`}>
          <TaskModalToggle open={false} openState={[modalOpen, setModalOpen]} />
        </Route>
      </Switch>
      <CardHeader
        avatar={getAvatar()}
        title={task.name}
        subheader={
          <>
            {getDistanceElement()}
            {inactive && !task.solution && getInactiveReasonsElement()}
          </>
        }
        action={
          <>
            <IconButton
              component={Link}
              onClick={handleMarkerClick}
              to={`${path}/map`}>
              <MarkerIcon
                className={clsx(classes.markerIcon, [
                  inactive && classes.markerIconDisabled,
                ])}
              />
            </IconButton>
          </>
        }
      />
      <Collapse in={!inactive}>
        <CardMedia
          className={classes.media}
          image={task.thumbnailUrl || task.imageUrl}
          title={task.name}
        />
        <CardContent className={classes.shortDescription}>
          <Typography variant="body2" color="textSecondary">
            <Markdown text={task.shortDescription} />
          </Typography>
          {task.previewAudioUrl && (
            <AudioPlayer
              src={task.previewAudioUrl}
              spacing={2}
              variation="default"
              elevation={0}
              volume={false}
              useStyles={useAudioStyles}
            />
          )}
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            component={Link}
            to={`${path}/list/${task.id}`}>
            Zur Aufgabe
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
};
